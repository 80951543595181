import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {SnackbarContent, Typography} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';

import Utils from '../api/api';
import theme,{styles} from '../layout/theme';

import WebConfig from '../api/config';

var config = WebConfig.production;
if (window.location.hostname === 'localhost') {
  config = WebConfig.development;
}


class Dashboard extends Component {
  componentDidMount(){
    // console.log('---------');
    // console.log(this.context.router);
  }
  render() {
    const classes = this.props.classes;
    const denied = this.context.router.route.location.pathname.indexOf(':denied') !==-1;
    return (
      <div className={classes.container}>
        <form className={classes.form} noValidate>
        {
          denied && (
            <SnackbarContent
              className={classes.messageRow}
              aria-describedby="client-snackbar"
              message={
                <div id="client-snackbar" className={classes.formRowInline}>
                  <WarningIcon/>
                  <Typography style={{color: '#fff'}}>Bạn không có quyền truy cập khu vực này!</Typography>
                </div>
              }
            />
          )  
        }
        </form>
      </div>
    );
  }
}

Dashboard.contextTypes = {
  router: PropTypes.object
};


export default withStyles(styles)(Dashboard);
